/**
 * 页面多次出现的样式
 */

/**
 * 颜色
 */
.primary-color {
  color: rgba(27, 190, 143, 1);
}
.primary-red {
  color: #f00;
}

// 相邻按钮之间间距
.btn-space {
  & + .btn-space {
    margin-left: 20px;
  }
}

// 通用卡片
.card-wrapper {
  margin: 15px 0;
  padding: 15px 15px;
  border-radius: 8px;
  background: #fff;
}

// 必填*标识
.required {
  margin: 0 5px;
  color: red;
}

// 右侧drawer选择
.drawer-wrapper {
  .radio-item {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    border: 1px #fff solid;
    &:hover {
      border: 1px #1bbe8f solid;
    }
    & > span:nth-child(2) {
      width: 100%;
    }
  }
}

// 自定义antd type 按钮
.ant-btn-custom-yellow {
  background-color: #fbd038;
  color: #fff;
  border: 1px #fbd038 solid;
  &:hover,
  &:focus {
    color: #fff;
    border-color: #fcde6fe6;
    background: #fcde6fe6;

    // border: 1px #FBD038 solid;
    // background-color: #fff;
    // color: #FBD038;
  }
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
}

.ant-notification-notice-message {
  white-space: pre-wrap !important;
}

.ant-descriptions-item-content {
  white-space: pre-line !important;
}
