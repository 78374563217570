.jc-toast-mask {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  z-index: 4030;
}

.jc-toast-content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 4030;
}

.jc-toast {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  min-width: 120px;
  max-width: 500px;
  top: 45%;
  pointer-events: none;
  -webkit-animation: fadeIn 0.3s;
  animation: fadeIn 0.3s;
  z-index: 4030;
}
.jc-toast-info {
  padding: 20px;
  min-width: 120px;
  display: flex;
  border-radius: 8px;
  max-width: 500px;
  word-break: break-word;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  line-height: 25px;
}
.jc-toast-input-error {
  padding: 10px 20px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  line-height: 25px;
}

@keyframes fadeIn {
  0% {
    transform: translate(-50%, 150%) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
